<template>
    <div class="mt-5 px-xl-5">
        <b-row class="justify-content-center">
            <b-col lg="8" xl="6">
                <h6 class="page-title pb-2 text-uppercase">
                    {{ $t("superintendece-invitation.title") }}
                </h6>
            </b-col>
        </b-row>
        <b-row class="mb-5 justify-content-center">
            <b-col lg="8" xl="6">
                <b-card
                    class="shadow-sm border-0 rounded-0 px-md-4"
                    bg-variant="light"
                    no-header
                >
                    <b-card-text>
                        <b-row class="mt-2">
                            <b-col>
                                <b-form-group>
                                    <label
                                        class="form-title"
                                        label-for="name-input"
                                        >{{ $t("general.email") }}</label
                                    >
                                    <b-form-input
                                        id="name-input"
                                        v-model="email"
                                        size="sm"
                                        required
                                        class="rounded-0"
                                    ></b-form-input>
                                </b-form-group>
                            </b-col>
                        </b-row>
                        <b-row class="my-4">
                            <b-col class="text-end">
                                <b-overlay
                                    :show="sending"
                                    rounded
                                    opacity="0.7"
                                    spinner-small
                                    spinner-variant="primary"
                                    class="d-inline-block"
                                >
                                    <b-button
                                        class="main-btn rounded px-4"
                                        :disabled="!canSend"
                                        @click="sendInvitation"
                                        >{{ $t("general.send") }}</b-button
                                    >
                                </b-overlay>
                            </b-col>
                        </b-row>
                    </b-card-text>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>

<script>
import { RepositoryFactory } from "@/services/api";
import { mapActions } from "vuex";

export default {
    name: "superintendence-invitation",
    data() {
        return {
            sending: false,
            email: "",
        };
    },
    computed: {
        canSend() {
            return this.email !== "";
        },
    },
    methods: {
        ...mapActions("modals", ["openModal", "setModalInfo"]),
        async sendInvitation() {
            this.sending = true;
            try {
                let request = {
                    superintendenceInvitation: {
                        email: this.email,
                    },
                };
                await RepositoryFactory.superintendence.sendInvitation(request);
                this.setSuccesInfo();
            } catch (error) {
                window.console.log(error);
                this.setErrorInfo();
            }
            this.openModal();
            this.sending = false;
        },
        setSuccesInfo() {
            this.setModalInfo({
                title: "Invitación enviada",
                message:
                    "Se ha enviado la invitación de registro correctamente.",
                routeName: "superintendences",
            });
        },
        setErrorInfo() {
            this.setModalInfo({
                title: "Error enviando la invitación",
                message:
                    "Se ha producido un error al intentar enviar la invitación.",
                subMessage: "Por favor vuelva a intentarlo nuevamente",
            });
        },
    },
};
</script>